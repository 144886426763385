// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Make sure sweet alert appears on top of modal */\n.UserModal_alert__container__2Yart {\n  z-index: 100000;\n}\n", "",{"version":3,"sources":["webpack://src/components/UserModal/UserModal.module.css"],"names":[],"mappings":"AAAA,kDAAkD;AAClD;EACE,eAAe;AACjB","sourcesContent":["/* Make sure sweet alert appears on top of modal */\n.alert__container {\n  z-index: 100000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert__container": "UserModal_alert__container__2Yart"
};
export default ___CSS_LOADER_EXPORT___;
