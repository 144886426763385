import React, { useState, useEffect } from 'react';
import './style.css';
import { createBrowserHistory } from 'history';
import ColumnsRepresentation from '../Table/ColumnsRepresentation';
import {
  Table,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography
} from '@material-ui/core';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { Pagination } from 'antd';
import Filters from '../InquiryFilters';
import SpinnerLoader from '../SpinnerLoader';
import { getValue } from '../../utils/GetObjectValues';
import _ from 'lodash';

function LivePreviewExample({
  layoutStructure,
  layoutData,
  filters,
  setFilters,
  handleFilter,
  currentTab,
  setPage,
  totalPurposesCount,
  limit,
  loading,
  user,
  setSubmitTaskLoader,
  page,
  filtersFillInp,
  inquiryType
}) {
  let history = createBrowserHistory({ forceRefresh: true });
  const [tableLayout, setTableLayout] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [SelectedCheckbox, setSelectedCheckbox] = useState([]);
  const statusInfo = {
    Pending: { translation: 'قيد التنفيذ', color: 'text-warning' },
    Deserved: { translation: 'مستحقة', color: 'text-success' },
    Canceled: { translation: 'تم الرفض', color: 'text-danger' },
    NotDeserved: { translation: 'غير مستحقة', color: 'text-danger' },
    Finished: { translation: 'منتهيه', color: 'text-success' }
  };
  const redirectInstance = (instance) => {
    if (layoutStructure[currentTab].isDraft)
      history.push(`/programs/${instance.form_id}/${instance.id}`);
    else {
      if (layoutStructure[currentTab].redirectKey) {
        instance.id = getValue(
          instance,
          layoutStructure[currentTab].redirectKey
        );
      }
      history.push(`/details/${instance.id}`);
    }
  };
  const extractRowData = (row, rowIndex) => {
    return tableLayout.map((column, index) => {
      let color;
      row[column.key] = getValue(row, column.key);
      if (column.key.includes('status')) {
        let statusValue = getValue(row, column.key);
        row[column.key] =
          (statusInfo[statusValue] && statusInfo[statusValue]['translation']) ||
          statusValue;
        color =
          (statusInfo[statusValue] && statusInfo[statusValue]['color']) ||
          Object.values(statusInfo).find(
            (status) => status.translation == statusValue
          )?.color;
      }
      return (
        <ColumnsRepresentation
          rowsCount={layoutData.length}
          key={index}
          cellType={column.inputLayout.type}
          rowIndex={rowIndex}
          index={index}
          value={row[column.key]}
          color={color}
          history={history}
          row={row}
          setSubmitTaskLoader={setSubmitTaskLoader}
          column={column}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setSelectedCheckbox={setSelectedCheckbox}
          SelectedCheckbox={SelectedCheckbox}
          currentTab={currentTab}
        />
      );
    });
  };
  const tableData =
    layoutData && layoutData.length > 0 ? (
      layoutData.map((row, index) => (
        <TableRow key={index} onClick={(e) => redirectInstance(row)}>
          {extractRowData(row, index)}
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell align="center" colSpan="9">
          <h5 className="m-top">ﻻ توجد نتائج</h5>
        </TableCell>
      </TableRow>
    );
  //pagination
  function itemRender(current, type, originalElement) {
    if (type === 'page') {
      return (
        <a className="pagination-color">{current.toLocaleString('ar-EG')}</a>
      );
    }
    if (type === 'prev') {
      return <a className="prev-next-border">السابق</a>;
    }
    if (type === 'next') {
      return <a className="prev-next-border">التالى</a>;
    }
    return originalElement;
  }
  const handlePagination = (page, pageSize) => {
    setPage(page > 0 ? page : 1);
    // setLimit(pageSize);
  };
  useEffect(() => {
    if (
      !_.isEmpty(layoutStructure) &&
      !layoutStructure[currentTab].hasOwnProperty('headerName')
    ) {
      let layout = [...layoutStructure[currentTab]['Columns']['shared']];
      user &&
        user.roles.forEach((role) => {
          if (layoutStructure[currentTab]['Columns'][role.name]) {
            layout = [
              ...layout,
              ...layoutStructure[currentTab]['Columns'][role.name]
            ];
          }
        });
      setTableLayout(layout);
    }
  }, [layoutStructure, currentTab]);
  useEffect(() => {
    if (layoutData)
      setSelectedCheckbox(new Array(layoutData.length).fill(false));
  }, [layoutData]);
  useEffect(() => {
    setPage(1);
    setSelectedRows([]);
  }, [currentTab]);
  return (
    <>
      <Card className="card-box mb-spacing-6-x2" style={{ borderRadius: '0' }}>
        {inquiryType == 'nationalId' ? (
          layoutData?.length > 0 ? (
            <Grid container spacing={2} Style="padding:10px">
              <Grid item xs={2}>
                <Typography>
                  <div>
                    <h6>الاسم</h6>
                    <p>
                      {`${layoutData[0]?.first_name} ${layoutData[0]?.family_name}`}
                    </p>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  <div>
                    <h6>المحافظه</h6>
                    <p>{layoutData[0]?.governorate} </p>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  <div>
                    <h6>الرقم القومي</h6>
                    <p>{layoutData[0]?.contact_id} </p>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  <div>
                    <h6>رقم الموبايل</h6>
                    <p>
                      {layoutData[0]?.phone ? layoutData[0]?.phone : 'لا يوجد'}{' '}
                    </p>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  <div>
                    <h6>طبيعة المستخدم</h6>
                    <p>
                      {layoutData[0]?.relation_type == 'benficiary'
                        ? 'مستفيد'
                        : 'قريب'}
                    </p>
                  </div>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography>
              <div>
                <h6>لا توجد بيانات</h6>
              </div>
            </Typography>
          )
        ) : (
          <>
            <Filters
              isDraft={
                !_.isEmpty(layoutStructure)
                  ? layoutStructure[currentTab]?.isDraft
                  : null
              }
              filtersFillInp={filtersFillInp}
              filters={filters}
              setFilters={setFilters}
              handleFilter={handleFilter}
            />
            <div className="table-container">
              {loading ? (
                <>
                  <SpinnerLoader />
                </>
              ) : (
                <>
                  <Table className="table table-alternate-spaced text-nowrap mb-0">
                    <TableHead className="font-size-sm text-uppercase">
                      <TableRow>
                        {tableLayout.map((column) => {
                          return (
                            <TableCell
                              className="text-center bg-secondary pt-2"
                              key={column.key}>
                              {column.name}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>{tableData}</TableBody>
                  </Table>
                </>
              )}
            </div>
            <div className="p-4 d-flex " style={{ justifyContent: 'flex-end' }}>
              <Pagination
                defaultCurrent={1}
                page={page}
                total={totalPurposesCount}
                pageSize={limit}
                responsive={true}
                showSizeChanger={false}
                onChange={handlePagination}
                itemRender={itemRender}
              />
            </div>
          </>
        )}
      </Card>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.list.loading,
    user: state.auth.user
  };
};
export default connect(mapStateToProps, null)(LivePreviewExample);
