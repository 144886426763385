// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs_tab--errored__1D5c6 {\n  color: red !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/Tabs/index.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB","sourcesContent":[".tab--errored {\n  color: red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab--errored": "Tabs_tab--errored__1D5c6"
};
export default ___CSS_LOADER_EXPORT___;
