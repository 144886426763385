import React, { useState, useEffect } from 'react';
import Selection from '../Selection';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { getUsers, handleTaskAssignment } from '../../../reducers/details';
import { reAssignUsers } from './config';
import Swal from 'sweetalert2';

const AssignmentComponent = ({
  handleTaskAssignment,
  task,
  user,
  users,
  getUsers
}) => {
  const [selectedUser, setSelectedUser] = useState('');
  const [taskUsers, setTaskUsers] = useState([]);
  const [distributedUsers, setDistributedUsers] = useState([]);
  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };
  useEffect(() => {
    setDistributedUsers([]);
  }, [task]);
  useEffect(() => {
    setDistributedUsers(users);
  },[users])
  const handleReAssign = () => {
    handleTaskAssignment({
      filter: {
        step_id: task?.step_id,
        submission_id: task?.submission_id,
        task_status: 'Pending'
      },
      user_id: selectedUser,
      submission_id: task?.submission_id
    }).then(() => {
      Swal.fire({
        title: 'تم',
        text: 'لقد تم إعادة التعيين بنجاح ',
        icon: 'success',
        timer: 3000
      });
    });
  };
  useEffect(() => {
    if (task?.users) {
      let roles = [];
      task.users.forEach((user) => {
        roles = [...roles, ...user.roles.map((role) => role.name)];
      });
      setTaskUsers([...new Set(roles)]);
    }

    let userRole_taskStepId_MappingObj = {
      'research manager' : [6,7,8],
      'research supervisor' : [7],
      'social division manager' : [14],
      'medical division manager' : [19],
      'legal manager' : [23],
      'financial manager' : [26],
      'admin' : [28],
      'administration office manager' : [4,38,40]
    };

    Object.entries(userRole_taskStepId_MappingObj).forEach(([key, value]) => {
      if (user.roles.some((role) => role.name == key)) {
        if (value.includes(task?.step.id)) {
          getUsers({
            assignees: task.step.assignees,
            submission_id: task.submission.id
          });
        }
      }
    });
  }, [task]);
  return (
    <Grid item>
      {user &&
        !_.isEmpty(taskUsers) &&
        reAssignUsers(user.roles, taskUsers) &&
        distributedUsers.length > 0 &&
        task?.submission?.archivingDetails?.length == 0 && (
          <Grid item sm={10} xs={12} container spacing={2} align="left">
            <Grid item sm={2}></Grid>
            <Selection
              Selectionlabel={
                task?.submission.status == 'Reinvestegation'
                  ? 'تعيين باحثين'
                  : 'إعادة تعيين'
              }
              SelectionValue={selectedUser}
              handleChange={handleChange}
              handleButton={handleReAssign}
              buttonLabel={'ارسال'}
              menuItems={users}
            />
          </Grid>
        )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    task: state.details.task,
    user: state.auth.user,
    users: state.details.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(getUsers(params)),
    handleTaskAssignment: (params) => dispatch(handleTaskAssignment(params))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentComponent);
