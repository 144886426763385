import { createReducer } from '@reduxjs/toolkit';
import {
  UPLOAD_FILE_PENDING,
  UPLOAD_FILE_FULFILLED,
  UPLOAD_FILE_REJECTED,
  SAVE_VALUES,
  ENABLE_FORM_SUBMIT_BUTTON,
  SAVE_FILES,
  GET_FORM_LAYOUT_PENDING,
  GET_FORM_LAYOUT_FULFILLED,
  SET_SUBMISSION,
  SAVE_DRAFT_PENDING,
  SAVE_DRAFT_FULFILLED,
  GET_DRAFT_PENDING,
  GET_DRAFT_FULFILLED,
  START_ENGINE_FULFILLED,
  START_ENGINE_PENDING,
  UPDATE_SUBMISSION_PENDING,
  UPDATE_SUBMISSION_FULFILLED,
  SET_FORM_NAME,
  SET_NATIONALIDS,
  HANDLE_DRAFT_ASSIGNMENT_PENDING,
  HANDLE_DRAFT_ASSIGNMENT_FULFILLED,
  SET_BENEFICIARY_ID,
  UPLOAD_Stamp_FILE_PENDING,
  UPLOAD_Stamp_FILE_FULFILLED,
  CLEAR_SUBMISSION,
  SAVE_DRAFT_REJECTED
} from './formActionTypes';

const initialState = {
  form: { name: '', sections: [] },
  fileUploadError: null,
  submission: { attachments: [] },
  nationalIds: [],
  loading: false,
  form_layout_loading: false,
  enableSubmit: false,
  files: [],
  draftValues: null,
  beneficiary_id: null,
  isUploadingFile: false,
  savedValues: {},
  savingDraftLoading: false
};

function setValue(obj, path, value) {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexepaths to propertis
  path = path.replace(/^\./, ''); // strip a leading dot
  path = path.split('.');
  while (path.length - 1) {
    let n = path.shift();
    if (!(n in obj)) obj[n] = {};
    obj = obj[n];
  }
  obj[path[0]] = value;
}

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(UPLOAD_FILE_PENDING, (state, action) => {
    state.loading = true;
    state.isUploadingFile = true;
  });
  builder.addCase(UPLOAD_FILE_REJECTED, (state, action) => {
    state.isUploadingFile = false;
  });
  builder.addCase(UPLOAD_FILE_FULFILLED, (state, action) => {
    state.loading = false;
    state.isUploadingFile = false;
    if (action.meta.input_id) {
      if (!state.submission) state.submission = {};
      if (!state.submission.attachments) state.submission.attachments = [];
      const oldAttachment = state.submission?.attachments?.find(
        (e) => e.name === action.meta.name
      );
      if (
        state.submission?.attachments &&
        Array.isArray(state.submission?.attachments)
      ) {
        state.submission.attachments = [
          ...state.submission?.attachments?.filter(
            (e) => e.name !== action.meta.name
          ),
          {
            ...action.payload.data.results,
            input_id: action.meta.input_id,
            name: action.meta.name,
            contact_id: action.meta.contact_id,
            original_file_name: action.meta.original_file_name,
            section: action.meta.section,
            sectionName: action.meta.sectionName,
            id: oldAttachment?.id,
            metadata: {
              ...action.payload.data.results.metadata,
              type: action.meta.type,
              preview: action.meta.preview
            }
          }
        ];
      }
    } else {
      setValue(
        state.submission,
        action.meta.name,
        action.payload.data.results?.avatarFd
      );
    }
  });
  builder.addCase(UPLOAD_Stamp_FILE_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(UPLOAD_Stamp_FILE_FULFILLED, (state, action) => {
    state.loading = true;
  });

  builder.addCase(SAVE_VALUES, (state, action) => {
    state.submission = { ...state.submission, ...action.payload };
    state.savedValues = { ...state.savedValue, ...action.payload };
  });
  builder.addCase(ENABLE_FORM_SUBMIT_BUTTON, (state, action) => {
    state.enableSubmit = true;
  });
  builder.addCase(SAVE_FILES, (state, action) => {
    state.files = [
      // ...state.files.filter(
      //   (e) => e.checklist_input_id !== action.payload.checklist_input_id
      // ),
      {
        file: action.payload.file,
        name: action.payload.name
      }
    ];
  });

  builder.addCase(START_ENGINE_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(START_ENGINE_FULFILLED, (state, action) => {
    state.loading = false;
    state.submission = null;
    state.enableSubmit = false;
  });

  builder.addCase(GET_FORM_LAYOUT_PENDING, (state, action) => {
    state.form_layout_loading = true;
  });
  builder.addCase(GET_FORM_LAYOUT_FULFILLED, (state, action) => {
    state.form_layout_loading = false;
    state.form = action.payload.data;
  });

  builder.addCase(SET_SUBMISSION, (state, action) => {
    state.submission = action.payload;
  });
  builder.addCase(GET_DRAFT_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(GET_DRAFT_FULFILLED, (state, action) => {
    state.loading = false;
    state.draftValues = action.payload.data.draft;
    state.submission = { attachments: action.payload.data.attachments };
  });
  builder.addCase(SAVE_DRAFT_PENDING, (state, action) => {
    state.loading = true;
    state.savingDraftLoading = true;
  });
  builder.addCase(SAVE_DRAFT_FULFILLED, (state, action) => {
    state.loading = false;
    state.draftValues = null;
    state.savingDraftLoading = false;
    state.submission = {
      attachments: state.submission.attachments.map((attachment) => {
        return { ...attachment, saved: true };
      })
    };
  });
  builder.addCase(SAVE_DRAFT_REJECTED, (state, action) => {
    state.loading = false;
    state.savingDraftLoading = false;
  });
  builder.addCase(CLEAR_SUBMISSION, (state, action) => {
    state.submission = {};
  });

  builder.addCase(UPDATE_SUBMISSION_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(UPDATE_SUBMISSION_FULFILLED, (state, action) => {
    state.loading = false;
    state.draftValues = null;
  });
  builder.addCase(SET_FORM_NAME, (state, action) => {
    state.form.name = action.payload;
  });

  builder.addCase(HANDLE_DRAFT_ASSIGNMENT_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(HANDLE_DRAFT_ASSIGNMENT_FULFILLED, (state, action) => {
    state.loading = false;
  });

  builder.addCase(SET_NATIONALIDS, (state, action) => {
    let result = state.nationalIds?.find(
      (nationalId) => nationalId.InputName == action.payload.InputName
    );
    if (result) {
      state.nationalIds = state.nationalIds?.map((nationalId) => {
        if (nationalId.InputName == action.payload.InputName) {
          nationalId.national_id = action.payload.national_id;
          nationalId.InputName = action.payload.InputName;
        }
        return nationalId;
      });
    } else {
      state.nationalIds =
        state.nationalIds.length > 0
          ? [
              ...state.nationalIds,
              {
                InputName: action.payload.InputName,
                national_id: action.payload.national_id
              }
            ]
          : [
              {
                InputName: action.payload.InputName,
                national_id: action.payload.national_id
              }
            ];
    }
  });

  builder.addCase(SET_BENEFICIARY_ID, (state, action) => {
    state.beneficiary_id = action.payload;
  });
});
export default reducer;
