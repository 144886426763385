import React, { useEffect, useState, useMemo } from 'react';

import { Grid, Card, ListItem, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { useDropzone } from 'react-dropzone';
import { uploadFile } from '../../reducers/form/formActions';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import { handleDocument } from '../../utils/uploadFile';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import client from '../../api/apiAuth/guestClient';
import _ from 'lodash';
import './style.css';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CameraModal from '../CameraModal';
import { apiConfig, handleError } from '../../api/utilities';
// import IndexedDB from '../../utils/indexedDB';
import { DecreaseRequestsNo } from 'reducers/general/generalActions';

const FileUploader = ({
  submission,
  input,
  getValue,
  formik,
  uploadFile,
  setSubmission,
  extraData,
  DecreaseRequestsNo,
  submissionDetails
}) => {
  const [files, setFiles] = useState([]);
  const [currentUploadedFiles, setCurrentUploadedFiles] = useState([]);
  const [rejectedFileErrorMessage, setRejectedFileErrorMessage] = useState([]);
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const maxCount = input.input_layout?.maxUploadedCount;
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'application/pdf',
    maxSize: 10000000,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (!(files.length + acceptedFiles.length > maxCount)) {
        acceptedFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
        if (acceptedFiles[0]?.type == 'application/pdf') {
          setCurrentUploadedFiles([...acceptedFiles]);
        } else {
          setRejectedFileErrorMessage([
            ...rejectedFileErrorMessage,
            { message: 'غير مسموح اﻻ برفع ملفات pdf ' }
          ]);
        }
        rejectedFiles.forEach((file) => {
          file.errors.forEach((err) => {
            let errorMessage;
            if (err.code === 'file-too-large') {
              errorMessage = 'حجم الملف كبير جدا ';
            }

            if (err.code === 'file-invalid-type') {
              errorMessage = 'غير مسموح اﻻ برفع ملفات pdfاو صور';
            }
            setRejectedFileErrorMessage([
              ...rejectedFileErrorMessage,
              { fileName: file.file.name, message: errorMessage }
            ]);
          });
        });
      } else {
        setRejectedFileErrorMessage([
          ...rejectedFileErrorMessage,
          { message: 'لقد تخطيت عدد المرفقات المسموح' }
        ]);
      }
    }
  });
  const removeFile = async (file) => {
    try {
      //find attachment in redux store
      submission = submission.attachments.length != 0 ? submission : submissionDetails;
      let attachment = submission.attachments?.find(
        (attachment) => file.preview == attachment.metadata?.preview
      );
      let avatarFd = attachment?.avatarFd;
      if (attachment) {
        //remove attachment file from backend
        await client.post(
          `attachment/deleteUnlinkedAttachment?avatarFd=${avatarFd}&&id=${attachment.id}`
        );
        //remove attachment from redux store
        setSubmission({
          ...submission,
          attachments: submission.attachments.filter(
            (attachment) => file.preview != attachment.metadata?.preview
          )
        });
      }

      // remove attachment from its section in formik
      let filteredFiles = getValue(formik.values, input.key).filter((value) => {
        if (value) {
          return value.preview
            ? file.preview != value.preview
            : file.preview != value.metadata.preview;
        }
        return false;
      });

      formik.setFieldValue(input.key, filteredFiles);
      setFiles(
        files.filter((value) =>
          value.preview
            ? file.preview != value.preview
            : file.preview != value.metadata.preview
        )
      );
    } catch (error) {
      handleError(error);
      console.log(error);
    }
  };

  const showFileImage = async (file) => {
    let img = new Image();
    let url = '';
    let binaryData = [];
    binaryData.push(file);
    let imageData;
    let extension = file.type?.split('/');
    if (!extension) extension = file.name?.split('.');
    extension = extension[extension.length - 1];
    if (!['jpeg', 'png', 'jpg', 'gif'].includes(extension)) extension = 'jpeg';
    if (file?.pdfUrl) {
      if (file.storage_type == 'externalWebsite') {
        imageData = await client.get(
          `${apiConfig.baseUrl}attachment/download/?id=${file.id}&storage_type=${file.storage_type}`,
          {
            responseType: 'blob',
            withCredentials: true
          }
        );
      } else {
        imageData = await client.get(file.pdfUrl, {
          responseType: 'blob',
          withCredentials: true
        });
      }
      url = URL.createObjectURL(
        new Blob([imageData.data], { type: `image/${extension}` })
      );
    } else
      url = URL.createObjectURL(
        new Blob(binaryData, { type: `image/${extension}` })
      );
    img.onload = () => {
      window.open(
        url,
        '_blank',
        `height=${img.height},width=${img.width},left=100,top=50`
      );
    };
    img.src = url;
  };

  const showFile = async (file) => {
    let fileURL, imageData;
    if (file.pdfUrl) {
      if (file.storage_type == 'externalWebsite') {
        imageData = await client.get(
          `${apiConfig.baseUrl}attachment/download/?id=${file.id}&storage_type=${file.storage_type}`,
          {
            responseType: 'blob',
            withCredentials: true
          }
        );
      } else {
        imageData = await client.get(file.pdfUrl, {
          responseType: 'blob',
          withCredentials: true
        });
      }
      fileURL = URL.createObjectURL(
        new Blob([imageData.data], { type: `application/pdf` })
      );
    } else {
      if (!_.isEmpty(file)) fileURL = URL.createObjectURL(file);
    }
    //Open the URL on new Window
    window.open(fileURL, '_blank', 'toolbar=0,menubar=0');
  };

  let uploadedAttachments = getValue(formik.values, input.key) || [];
  uploadedAttachments = uploadedAttachments.filter((cur) => cur != null);
  const thumbs = useMemo(
    () =>
      uploadedAttachments.map((file, index) => {
        let extension = file?.type?.split('/')[1];
        let binaryData = [];
        binaryData.push(file);
        // return file?.type?.includes('image') || file?.path?.split('.').pop() == 'pdf'? (
        //   <Grid item md={3} className="p-2" key={file.name + index}>
        //     <div className="p-2 bg-white shadow-xxl border-dark card-box d-flex overflow-hidden rounded-sm">
        //       <CloseIcon
        //         className="remove-img"
        //         onClick={() => removeFile(file)}
        //       />
        //       <img
        //         className="img-fluid img-fit-container rounded-sm"
        //         src={
        //           file?.pdfUrl
        //             ? file.pdfUrl
        //             : URL.createObjectURL(
        //                 new Blob(binaryData, { type: `image/${extension}` })
        //               )
        //         }
        //         alt={file.name}
        //         style={{ cursor: 'pointer' }}
        //         onClick={() => showFileImage(file)}
        //       />
        //     </div>
        //   </Grid>
        // ) :
        return (
          file && (
            <ListItem
              className="font-size-sm px-1 py-2 text-primary d-flex align-items-center"
              key={file.name + index}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => showFile(file)}
                className="file-name">
                {file.name}
              </span>
              {file.size && (
                <span className="badge badge-pill bg-neutral-warning text-warning ">
                  {file.size} bytes
                </span>
              )}
              <CloseIcon
                className="mr-1 remove-file"
                onClick={() => removeFile(file)}
              />
            </ListItem>
          )
        );
      }),
    [uploadedAttachments, submission, files, formik]
  );
  useEffect(() => {
    if (extraData && extraData.connectionStatus) {
      currentUploadedFiles.forEach((file, index) => {
        let documentName = input.key + `[${files.length + index}]`,
          keyParts = documentName.split('.'),
          sectionName = input.section,
          allFiles = currentUploadedFiles,
          input_id = input.itype !== 'string' && input.id;

        // console.log('multiple document upload', section, sectionName);

        //add file to formik
        let document = handleDocument(
          formik,
          keyParts,
          sectionName,
          documentName,
          file,
          allFiles,
          input_id,
          rejectedFileErrorMessage,
          setRejectedFileErrorMessage
        );
        //send file to server
        if (document.data && extraData && extraData.connectionStatus) {
          if(document.type == "application/pdf") {
            uploadFile(document)
              .then(() => {
                console.log('FILE UPLOADED!!!');
              })
              .catch((error) => {
                console.log('error', error);
                DecreaseRequestsNo();
                formik.setFieldValue(documentName, '');
              });
              if (extraData && !extraData.connectionStatus) {
                // var reader = new FileReader();
                // reader.readAsArrayBuffer(document.data);
                // reader.onload = function (e) {
                //   IndexedDB.addTemporaryAttachment({
                //     buffer: e.target.result,
                //     ...document
                //   });
                // };
                formik.setFieldValue(documentName, null);
              }
              setFiles([...files, ...currentUploadedFiles]);
          } 
        }
      });
    }
  }, [currentUploadedFiles]);
  //add old attachments to files array
  useEffect(() => {
    //add old attachments to files array
    !_.isEmpty(submission?.attachments) &&
      setFiles(
        submission.attachments.filter((attachment) =>
          attachment.name.includes(input.key)
        )
      );
  }, []);

  return (
    <Grid container>
      <Grid item sm={11}>
        <Card className="card-box mb-lg-0">
          <div>
            <div className="p-2 multi-upload-btn">
              <div
                {...getRootProps({ className: 'dropzone-upload-wrapper' })}
                className="p-0">
                <input
                  {...getInputProps()}
                  disabled={input.disabled}
                  onBlur={formik.handleBlur}
                  required={input.required}
                />
                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                  {isDragAccept && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                        <CheckIcon className="d-50" />
                      </div>
                      <div className="font-size-xs text-success">
                        We're ready to start!
                      </div>
                    </div>
                  )}
                  {isDragReject && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                        <CloseTwoToneIcon className="d-50" />
                      </div>
                      <div className="font-size-xs text-danger">
                        These files are not images!
                      </div>
                    </div>
                  )}
                  {!isDragActive && (
                    <div>
                      <div
                        className="font-size-sm"
                        style={{ textAlign: 'center', color: 'white' }}>
                        رفع ملفات
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {thumbs?.length > 0 && (
            <div className="card-footer p-3 bg-secondary">
              <div>
                <div className="font-weight-bold mb-3 text-uppercase text-dark font-size-sm text-center">
                  Uploaded Files
                </div>
                <div>
                  <Alert severity="success" className="text-center mb-3">
                    You have uploaded <b>{thumbs?.length}</b> files!
                  </Alert>
                  <Grid container spacing={0}>
                    {thumbs}
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </Card>
      </Grid>
      <Grid item sm={1}>
        <Button
          className={`btn btn-primary btn-transition-none d-inline camera-button`}
          style={{
            borderRadius: '10% 0 0 10%',
            height: '40px',
            backgroundColor: 'white',
            color: '#3c44b1',
            width: '40px'
          }}
          disableFocusRipple
          disableRipple
          disabled={input.disabled}
          onClick={() => setOpenCameraModal(true)}>
          <CameraAltIcon />
        </Button>
      </Grid>
      {/* camera modal */}
      <CameraModal
        open={openCameraModal}
        setOpen={setOpenCameraModal}
        documentType={'mutiple'}
        formik={formik}
        setCurrentUploadedFiles={setCurrentUploadedFiles}></CameraModal>
      <div>
        {rejectedFileErrorMessage.length > 0 &&
          rejectedFileErrorMessage.map((file, index) => {
            return (
              <ListItem
                className="font-size-sm px-1 py-2 text-primary d-flex align-items-center"
                key={file.fileName + index}>
                <span className="text-danger">{file.message}</span>
                <span className="file-name text-danger">{file.fileName}</span>
              </ListItem>
            );
          })}
      </div>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    submissionDetails: state.details?.task?.submission,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (file) => dispatch(uploadFile(file)),
    DecreaseRequestsNo: () => dispatch(DecreaseRequestsNo())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
