// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PdfViewer_modal__3MO_4 {\n  direction: ltr !important;\n}\n\n.PdfViewer_modal__3MO_4 div {\n  direction: ltr !important;\n}\n\n.PdfViewer_modal__3MO_4 canvas {\n  direction: ltr !important;\n}\n\n.PdfViewer_modal__3MO_4 span {\n  direction: ltr !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/PdfViewer/index.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".modal {\n  direction: ltr !important;\n}\n\n.modal div {\n  direction: ltr !important;\n}\n\n.modal canvas {\n  direction: ltr !important;\n}\n\n.modal span {\n  direction: ltr !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "PdfViewer_modal__3MO_4"
};
export default ___CSS_LOADER_EXPORT___;
